:root {
  // ========== Global ========== //
  // Font and Line Height
  --global-font-family: #{inspect($global-font-family)};
  --global-font-size: #{$global-font-size};
  --global-font-weight: #{$global-font-weight};
  --global-line-height: #{$global-line-height};
  // ========== Global ========== //

  // ========== Header ========== //
  // Height of the header
  --header-height: #{$header-height};

  // Font family and size of the header title
  --header-title-font-family: #{inspect($header-title-font-family)};
  --header-title-font-size: #{$header-title-font-size};
  // ========== Header ========== //

  // ========== Single Content ========== //
  // Font size of the TOC
  --toc-title-font-size: #{$toc-title-font-size};
  --toc-content-font-size: #{$toc-content-font-size};
  // ========== Single Content ========== //

  // ========== Code ========== //
  // Font family and size of the code
  --code-font-family: #{inspect($code-font-family)};
  --code-font-size: #{$code-font-size};
  // ========== Code ========== //
}
